import axios from 'axios';
import { useEffect, useReducer, useRef } from 'react';

import { useAlert } from './useAlert';
import { sendLogRest } from '@portal/lib';

const DefaultState = {
  data: null,
  error: null,
  isLoading: false,
};

const Action = {
  Start: 'loading',
  Success: 'fetched',
  Failure: 'error',
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case Action.Start:
      return {
        ...DefaultState,
        isLoading: true,
      };
    case Action.Success:
      return {
        ...DefaultState,
        data: action.payload,
        isLoading: false,
      };
    case Action.Failure:
      return {
        ...DefaultState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

/** Perform standard http requests */
export const useFetch = ({
  baseURL,
  errorText,
  isErrorAlertMuted = false,
  onError,
  onSuccess,
} = {}) => {
  const [state, dispatch] = useReducer(fetchReducer, DefaultState);
  const { setErrorAlert } = useAlert();

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef(false);

  useEffect(() => {
    // Use the cleanup function for avoiding a possibly state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const handleError = (error) => {
    if (!isErrorAlertMuted) {
      setErrorAlert(errorText);
    }
    onError?.(error);
    sendLogRest({ message: JSON.stringify(error), error: error });
  };

  const fetchData = async (config) => {
    cancelRequest.current = false;

    dispatch({ type: Action.Start });

    try {
      const response = await axios({
        baseURL,
        ...config,
      });

      if (cancelRequest.current) {
        return;
      }

      dispatch({ type: Action.Success, payload: response.data });

      onSuccess?.(response.data);

      return response.data;
    } catch (error) {
      console.error(error);

      if (cancelRequest.current) {
        return;
      }

      dispatch({ type: Action.Failure, payload: error });

      handleError(error);
    }
  };

  return [fetchData, state];
};
